body {
  margin: 0;
  font-family: 'ヒラギノ角ゴ ProN', 'Hiragino Kaku Gothic ProN', 'メイリオ', 'Meiryo', sans-serif;
  font-size: 14px;

  color: #1A0707;
  letter-spacing: 0.25px;
  text-align: center;
}

p {
  margin-top: 20px;
  margin-bottom: 40px;
}

button {
  border: none;
}

button:hover {
  cursor: pointer;
}

button:focus {
  outline: none;
}

.logo {
  width: 90%;
  max-width: 540px;
  margin-top: 20px;
}

.button {
  background-color: rgb(252, 114, 11);
  border-radius: 3px;
  color: #fff;
  padding: 5px 15px;
  font-size: 16px;
}
